<template>
    <div class="wrap">
        <AlertModal v-if="isAlertModal" :alertText="'저장되었습니다'" @closeModal="closeAlertModal()" />
        <StartPcAuto v-if="pcStart" @closePc="pcStart = false" @insertPc="insertStartTime" :userInfo="autoPlayInfo" />
        <EndPcAuto v-if="pcEnd" @closePc="pcEnd = false" @insertPc="insertEndTime" :userInfo="autoPlayInfo" />
        <StartMobileAuto v-if="mobileStart" @closeMobile="mobileStart = false" @insertMobile="insertStartTime"
                         :userInfo="autoPlayInfo" />
        <EndMobileAuto v-if="mobileEnd" @closeMobile="mobileEnd = false" @insertMobile="insertEndTime"
                       :userInfo="autoPlayInfo" />
        <!-- pc -->
        <div class="space_pc">
            <section class="main myspace w_940">
                <div class="main_box">
                    <div class="main_box_title">
                        <h1 style="margin-bottom: 0px;">자동 재생 설정</h1>
                        <div>
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"
                                 @click="clickPre()">
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white" />
                            </svg>
                        </div>
                    </div>
                    <div class="cs_main">
                        <div class="myspace_content frenchise" id="frenchise1">
                            <div class="alarm_setting no_pd">
                                <p style="margin-bottom: 0px;">자동 재생 기능</p>
                                <div class="alarm_btn_box">
                                    <div class="email_alarm">
                                        <div class="alarm_box" id="alarm_box1">
                                            <span :class="{ active1: autoPlayInfo.useAt === 'Y' }" @click="updateUseAt()"></span>
                                            <span class="move" :class="{ active1_1: autoPlayInfo.useAt === 'Y' }" @click="updateUseAt()"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="choice_date choice_date1">
                                <p>요일 설정</p>
                                <div class="choice_date3">
                                    <span :class="{ on: autoPlayInfo.mon === 'N' }" @click="updateMon()">월</span>
                                    <span :class="{ on: autoPlayInfo.tue === 'N' }" @click="updateTue()">화</span>
                                    <span :class="{ on: autoPlayInfo.wed === 'N' }" @click="updateWed()">수</span>
                                    <span :class="{ on: autoPlayInfo.thu === 'N' }" @click="updateThu()">목</span>
                                    <span :class="{ on: autoPlayInfo.fri === 'N' }" @click="updateFri()">금</span>
                                    <span :class="{ on: autoPlayInfo.sat === 'N' }" @click="updateSat()">토</span>
                                    <span :class="{ on: autoPlayInfo.sun === 'N' }" @click="updateSun()">일</span>
                                </div>
                                <div class="set_clock">
                                    <p>시간</p>
                                    <div>
                                        <div class="start_clock" @click="selectStartTime()">
                                            <p style="margin: 0;">{{ autoPlayInfo.startTime }}</p>
                                        </div>
                                        ~
                                        <div class="end_clock" @click="selectEndTime()">
                                            <p style="margin: 0;">{{ autoPlayInfo.endTime }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="auto_play_notice auto_play_notice1">
                                <h5>유의사항</h5>
                                <ul>
                                    <li><span style="margin-bottom: 0px;"></span>
                                        <p style="margin-bottom: 0px;">설정하신 시간에 플레이어가 자동으로 on/off 됩니다.</p>
                                    </li>
                                    <li><span style="margin-bottom: 0px;"></span>
                                        <p style="margin-bottom: 0px;">자동 재생은 '이용중'인 스페이스에서만 작동합니다.</p>
                                    </li>
                                    <li><span style="margin-bottom: 0px;"></span>
                                        <p style="margin-bottom: 0px;">자동 재생은 자동로그인 상태에서만 작동합니다.</p>
                                    </li>
                                    <!-- <li><span></span><p>아이폰 앱의 경우, 해당 기능 미노출</p> </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 -->
        <div class="space_mo">
            <!-- <div class="back_dim"></div> -->
            <div class="mo_space_header">
                <div class="mo_header_box">
                    <a>
                        <img src="/media/images/icon_back_24.svg" @click="clickPre()">
                    </a>
                    <h2>자동 재생 설정</h2>
                    <div></div>
                </div>
            </div>
            <section class="main myspace auto_play">
                <div class="main_box">
                    <div class="cs_main">
                        <!--<div class="side_depth_box">
              <div class="one_depth">
                <a class="one_depth_btn" href="myspace_1.html"><span></span>스페이스 설정</a>
                <a class="one_depth_btn" href="myspace_2.html"><span></span>큐레이션 설정</a>
                <a class="one_depth_btn" href="myspace_5.html"><span></span>프랜차이즈 연결</a>
                <a class="one_depth_btn on" href="myspace_6.html"><span class="on"></span>자동재생 설정</a>
              </div>
            </div>-->
                        <div class="myspace_content frenchise" id="frenchise2">
                            <div class="alarm_setting no_pd">
                                <p>자동 재생 기능</p>
                                <div class="alarm_btn_box">
                                    <div class="email_alarm">
                                        <div class="alarm_box" id="alarm_box2">
                                            <span :class="{ active1: autoPlayInfo.useAt === 'Y' }" @click="updateUseAt()"></span>
                                            <span class="move" :class="{ active1_1: autoPlayInfo.useAt === 'Y' }" @click="updateUseAt()"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="choice_date choice_date2">
                                <p>요일 설정</p>
                                <div class="choice_date3">
                                    <span :class="{ on: autoPlayInfo.mon === 'N' }" @click="updateMon()">월</span>
                                    <span :class="{ on: autoPlayInfo.tue === 'N' }" @click="updateTue()">화</span>
                                    <span :class="{ on: autoPlayInfo.wed === 'N' }" @click="updateWed()">수</span>
                                    <span :class="{ on: autoPlayInfo.thu === 'N' }" @click="updateThu()">목</span>
                                    <span :class="{ on: autoPlayInfo.fri === 'N' }" @click="updateFri()">금</span>
                                    <span :class="{ on: autoPlayInfo.sat === 'N' }" @click="updateSat()">토</span>
                                    <span :class="{ on: autoPlayInfo.sun === 'N' }" @click="updateSun()">일</span>
                                </div>
                                <div class="set_clock">
                                    <p>시간</p>
                                    <div>
                                        <div class="start_clock" @click="selectStartTimeMo()">
                                            <p style="margin: 0;">{{ autoPlayInfo.startTime }}</p>
                                        </div>
                                        ~
                                        <div class="end_clock" @click="selectEndTimeMo()">
                                            <p style="margin: 0;">{{ autoPlayInfo.endTime }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="auto_play_notice auto_play_notice2">
                                <h5>유의사항</h5>
                                <ul>
                                    <li><span></span>
                                        <p style="margin: 0;">설정하신 시간에 플레이어가 자동으로 on/off 됩니다.</p>
                                    </li>
                                    <li><span></span>
                                        <p style="margin: 0;">자동 재생은 '이용중'인 스페이스에서만 작동합니다.</p>
                                    </li>
                                    <li><span></span>
                                        <p style="margin: 0;">자동 재생은 자동로그인 상태에서만 작동합니다.</p>
                                    </li>
                                    <!-- <li><span></span><p style="margin: 0;">아이폰 앱의 경우, 해당 기능 미노출</p> </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="auto_form">
            <button @click="updateAutoPlayApi">확인</button>
        </div>
    </div>
</template>

<script>
import StartPcAuto from "@/components/modal/AutoPlay/StartPcAuto.vue";
import EndPcAuto from '@/components/modal/AutoPlay/EndPcAuto.vue';
import StartMobileAuto from '@/components/modal/AutoPlay/StartMobileAuto.vue';
import EndMobileAuto from '@/components/modal/AutoPlay/EndMobileAuto.vue';
import $ from 'jquery';
import axios from 'axios';
import VueCookies from "vue-cookies";
import AlertModal from "@/components/modal/Space/AlertModal.vue";
export default {
  data () {
    return {
      pcStart: false,
      pcEnd: false,
      mobileStart: false,
      mobileEnd: false,
      autoPlayInfo: {},
      week: [],
      isAlertModal: false
    };
  },
  components: {
    StartPcAuto,
    EndPcAuto,
    StartMobileAuto,
    EndMobileAuto,
    AlertModal
  },
  methods: {
    checkWeek () {
      this.week = [];
      if (this.autoPlayInfo.sun === 'Y') {
        this.week.push('Y');
      }
      if (this.autoPlayInfo.mon === 'Y') {
        this.week.push('Y');
      }
      if (this.autoPlayInfo.tue === 'Y') {
        this.week.push('Y');
      }
      if (this.autoPlayInfo.wed === 'Y') {
        this.week.push('Y');
      }
      if (this.autoPlayInfo.thu === 'Y') {
        this.week.push('Y');
      }
      if (this.autoPlayInfo.fri === 'Y') {
        this.week.push('Y');
      }
      if (this.autoPlayInfo.sat === 'Y') {
        this.week.push('Y');
      }
    },
    setAutoPlay () {
      var spaceId = localStorage.getItem("spaceId");
      if (spaceId === VueCookies.get("spaceId")) {
        // 기등록된 job삭제
        this.stopAutoPlay();
        var useAt = this.$store.state.autoPlayInfo.useAt;
        if (useAt === 'Y') { // 자동재생 기능 사용 시 수정된 job으로 재등록
          this.$crontab.addJob([{
            name: 'startAutoPlay',
            interval: {
              seconds: '0',
              minutes: this.$store.state.autoPlayInfo.startMinutes,
              hours: this.$store.state.autoPlayInfo.startHours,
              week: (this.$store.state.autoPlayInfo.week).toString()
            },
            job: this.setPlay
          },
          {
            name: 'endAutoPlay',
            interval: {
              seconds: '0',
              minutes: this.$store.state.autoPlayInfo.endMinutes,
              hours: this.$store.state.autoPlayInfo.endHours,
              week: (this.$store.state.autoPlayInfo.week).toString()
            },
            job: this.setPause
          }]);
        }
      }
    },
    setPlay () {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf('aplayzios') === -1 && userAgent.indexOf('aplayznativeaos') === -1 && userAgent.indexOf('aplayzios') === -1) {
        this.$store.commit("setPauseClickValue", 'N');
        this.$store.commit("setCountMusicPlayInPause", 0);
        var video = document.getElementById("video");
        if (!this.$store.state.isMute) video.muted = false;
        video.volume = this.$store.state.volume;
        video.play();
        this.$store.commit("setPlay", true);
        console.log("play!");
        var today = new Date();
        console.log('자동재생 시작 : ' + today);
      }
    },
    setPause () {
      this.$store.commit("setPauseClickValue", 'Y');
      var video = document.getElementById("video");
      video.volume = 0;
      video.muted = true;
      // video.pause();
      this.$store.commit("setPlay", false);
      console.log("pause!");
      var today = new Date();
      console.log('자동재생 스탑 : ' + today);
    },
    stopAutoPlay () {
      this.$crontab.deleteJob('startAutoPlay');
      this.$crontab.deleteJob('endAutoPlay');
    },
    selectStartTime () {
      this.pcStart = true;
    },
    selectEndTime () {
      this.pcEnd = true;
    },
    selectStartTimeMo () {
      this.mobileStart = true;
    },
    selectEndTimeMo () {
      this.mobileEnd = true;
    },
    updateMon () {
      if (this.week.length > 1 || this.autoPlayInfo.mon === 'N') {
        this.autoPlayInfo.mon === 'Y' ? this.autoPlayInfo.mon = 'N' : this.autoPlayInfo.mon = 'Y';

        this.checkWeek();
      }
    },
    updateTue () {
      if (this.week.length > 1 || this.autoPlayInfo.tue === 'N') {
        this.autoPlayInfo.tue === 'Y' ? this.autoPlayInfo.tue = 'N' : this.autoPlayInfo.tue = 'Y';

        this.checkWeek();
      }
    },
    updateWed () {
      if (this.week.length > 1 || this.autoPlayInfo.wed === 'N') {
        this.autoPlayInfo.wed === 'Y' ? this.autoPlayInfo.wed = 'N' : this.autoPlayInfo.wed = 'Y';

        this.checkWeek();
      }
    },
    updateThu () {
      if (this.week.length > 1 || this.autoPlayInfo.thu === 'N') {
        this.autoPlayInfo.thu === 'Y' ? this.autoPlayInfo.thu = 'N' : this.autoPlayInfo.thu = 'Y';

        this.checkWeek();
      }
    },
    updateFri () {
      if (this.week.length > 1 || this.autoPlayInfo.fri === 'N') {
        this.autoPlayInfo.fri === 'Y' ? this.autoPlayInfo.fri = 'N' : this.autoPlayInfo.fri = 'Y';

        this.checkWeek();
      }
    },
    updateSat () {
      if (this.week.length > 1 || this.autoPlayInfo.sat === 'N') {
        this.autoPlayInfo.sat === 'Y' ? this.autoPlayInfo.sat = 'N' : this.autoPlayInfo.sat = 'Y';

        this.checkWeek();
      }
    },
    updateSun () {
      if (this.week.length > 1 || this.autoPlayInfo.sun === 'N') {
        this.autoPlayInfo.sun === 'Y' ? this.autoPlayInfo.sun = 'N' : this.autoPlayInfo.sun = 'Y';

        this.checkWeek();
      }
    },
    updateUseAt () {
      if (this.autoPlayInfo.useAt === 'Y') {
        this.autoPlayInfo.useAt = 'N';
      } else {
        this.autoPlayInfo.useAt = 'Y';
      }
      this.mainOnOff();
    },
    async getAutoPlay () {
      var spaceId = localStorage.getItem("spaceId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      await axios
        .post("/api/profile/getAutoPlay", { spaceId }, { headers })
        .then((res) => {
          // console.log(res.data);
          this.autoPlayInfo = res.data.result;
          if (this.autoPlayInfo.useAt === 'Y') {
            $('.choice_date').show();
            $('.auto_play_notice').show();
          } else {
            $('.choice_date').hide();
            $('.auto_play_notice').hide();
          }

          if (spaceId === VueCookies.get("spaceId")) {
            // vuex 셋팅
            var result = res.data.result;
            var autoPlayInfo = {};
            // autoplay 요일지정
            autoPlayInfo.week = [];
            if (result.sun === 'Y') {
              autoPlayInfo.week.push(0);
            }
            if (result.mon === 'Y') {
              autoPlayInfo.week.push(1);
            }
            if (result.tue === 'Y') {
              autoPlayInfo.week.push(2);
            }
            if (result.wed === 'Y') {
              autoPlayInfo.week.push(3);
            }
            if (result.thu === 'Y') {
              autoPlayInfo.week.push(4);
            }
            if (result.fri === 'Y') {
              autoPlayInfo.week.push(5);
            }
            if (result.sat === 'Y') {
              autoPlayInfo.week.push(6);
            }
            // autoplay 시작시간
            autoPlayInfo.startHours = result.startTime.substring(0, 2);
            autoPlayInfo.startMinutes = result.startTime.substring(3, 5);
            // autoplay 종료시간
            autoPlayInfo.endHours = result.endTime.substring(0, 2);
            autoPlayInfo.endMinutes = result.endTime.substring(3, 5);
            // autoplay 사용여부
            autoPlayInfo.useAt = result.useAt;
            this.$store.commit('setAutoPlay', autoPlayInfo);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    linkMySpace () {
      this.$router.push({ name: "MySpace" });
    },
    clickPre () {
      history.back();
    },
    mainOnOff () {
      if (this.autoPlayInfo.useAt === 'Y') {
        $('.choice_date').show();
        $('.auto_play_notice').show();
      } else {
        $('.choice_date').hide();
        $('.auto_play_notice').hide();
      }
    },
    insertStartTime (time) {
      this.autoPlayInfo.startTime = time.hour + ':' + time.minute;
    },
    insertEndTime (time) {
      this.autoPlayInfo.endTime = time.hour + ':' + time.minute;
    },
    updateAutoPlayApi () {
      var spaceId = localStorage.getItem("spaceId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };
      const { useAt, mon, tue, wed, thu, fri, sat, sun, startTime, endTime } = this.autoPlayInfo;

      axios.post("/api/profile/updateAutoPlay", { spaceId, useAt, mon, tue, wed, thu, fri, sat, sun, startTime, endTime }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            this.isAlertModal = true;
            this.getAutoPlay();
            setTimeout(() => {
              this.setAutoPlay();
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeAlertModal () {
      this.isAlertModal = false;
      this.$router.push({ name: "MySpace" }).catch(() => { });
    }

  },
  created () {
    this.getAutoPlay();
  },
  mounted () {
    $('.choice_date').hide();
    $('.auto_play_notice').hide();
    setTimeout(() => {
      this.checkWeek();
    }, 500);
  }
};
</script>

<style scoped>
.wrap {
  margin: 0 auto;
}

.modal {
  display: block;
}

.auto_play_notice ul {
  padding: 0;
}

.auto_form {
  max-width: 940px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.auto_form button {
  width: 120px;
  height: 60px;
  border-radius: 8px;
  background: var(--secondary);
  color: #fff;
  font-size: 16px;
  line-height: 60px;
  font-weight: 500;
  transition: all 0.3s;
}

@media all and (max-width:500px) {

  .auto_form {
    position: fixed;
    bottom: 0;
    padding: 16px 5%;
  }

  .auto_form button {
    width: 100%;
  }
}
</style>

<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
